import { AppStore } from '../../common/types';
import { setMainPost } from '../store/main-post';

export type Post = {
  id: string;
  relatedPostIds: string[];
  categoryIds: string[];
};

export function createRelatedPostsApi(store: AppStore) {
  return {
    setMainPost: (post: Post) => {
      if (post) {
        store.dispatch(setMainPost(post));
      }
    },
  };
}

export type RelatedPostsApi = ReturnType<typeof createRelatedPostsApi>;
